body {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-family: 'Lobster', cursive;
}

h2 {
  font-family: 'Lobster', cursive;
  color: #3046c5;
}

h3 {
  font-family: 'Lobster', cursive;
}

.cardStyle {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
}

.card-body {
  padding: 0.9375rem 1.875rem;
  flex: 1 1 auto;
}

.card-title {
  font-weight: 500;
  font-size: 1.25rem;
  font-family: 'Lobster', cursive;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}

.site-footer {
  background-image: linear-gradient(#8a0606, white);
  padding: 20px 0;
  margin-top: 0;
}

.navbar-brand {
  max-width: 4rem;
  margin-right: 2rem;
}

.navbar-brand img {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  max-width: 4rem;
}

.navbar-dark {
  background-color: #901cad;
  background-image: linear-gradient(to right, #d66666, #921717, #4d0619);
  margin-bottom: 0;
  min-height: 8rem;
  position: sticky top;
  
}

.imgStyle {
  max-height: 128;
  max-width: 128;
}

.headerBackground {
  display: flex;
  background-color: #390714;
  background-size: cover;
  min-height: auto;
  align-content: center;
}

.headerImg {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center;
  margin: auto auto;
}

.mediaStyle {
  padding: 1px;
  border-color: #390714;
  border: 2px;
  margin: 5px;
  border-radius: 10px
}

.cardImg {
  height: 90px
}